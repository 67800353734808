<template>
  <div>
    <top-navigation-component/>
    <div class='p-24'>
      <div class='cak m-auto'>
        <div class='flex mt-8 text-gray-700'>
          <router-link class='flex'
            :to='{ name: "home" }'>
            <i class='icon'>arrow_back</i>
          </router-link>
          <div class='ml-16 text-lg font-bold'>{{ $t('pages.compatible-devices.134d0414') }}</div>
        </div>
        <div class='flex items-center py-8 px-24 mt-24 bg-white rounded shadow-sm'>
          <i class='icon text-gray-700'>search</i>
          <input-element class='pon ml-16 w-full'
            type='search'
            v-model='search'
            :placeholder='$t("pages.compatible-devices.498945f0")'/>
        </div>
        <skeleton-loader-component class='mt-24'
          :loading='!filteredCompatibleDevices'>
          <card-element class='py-16'
            v-if='filteredCompatibleDevices && filteredCompatibleDevices.length'>
            <div class='py-8'
              v-for='(device, index) in filteredCompatibleDevices'
              :key='index'>
              {{ device }}
            </div>
          </card-element>
          <div v-else>
            <div class='text-center text-gray-500'>{{ $t('pages.compatible-devices.c4714784') }}</div>
          </div>
        </skeleton-loader-component>
      </div>
    </div>
    <bottom-navigation-component/>
  </div>
</template>

<script>
  import BottomNavigation from '@/components/bottom-navigation'
  import SkeletonLoader from '@/components/skeleton-loader'
  import TopNavigation from '@/components/top-navigation'

  export default {
    components: {
      'bottom-navigation-component': BottomNavigation,
      'skeleton-loader-component': SkeletonLoader,
      'top-navigation-component': TopNavigation
    },

    data: () => ({
      search: ''
    }),

    computed: {
      compatibleDevices () {
        return this.$store.state.settings.compatibleDevices
      },

      filteredCompatibleDevices () {
        if (!this.compatibleDevices) return null

        return this.compatibleDevices.filter(device => (
          new RegExp(this.search, 'i').test(device)
        ))
      }
    }
  }
</script>

<style lang='scss' scoped>
  .cak {
    max-width: 480px;
  }

  .pon::v-deep .inp {
    @apply
      px-0
      border-0;
  }
</style>
